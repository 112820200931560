<template>
  <h2 v-if="wordLen == 0">
    En attente du nombre de lettres par l'initiateur du jeu
  </h2>
  <form class="col-md-6 mx-auto" v-else>
    <h2>Choisis un mot de {{ wordLen }} lettres</h2>
    <div class="input-group has-validation">
      <input
        type="text"
        v-model="word"
        :required="wordInvalid"
        :class="['form-control py-2', wordInvalid ? 'is-invalid' : '']"
        placeholder="Mot"
        aria-label="Mot"
      />
      <button
        class="btn btn-primary py-2"
        type="button"
        @click="$emit('set-word', word.toUpperCase())"
      >
        Valider
      </button>
      <div class="invalid-feedback text-start" v-if="wordInvalid">
        Veuillez renseigner un mot sans espace de {{ wordLen }} lettres
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "ChooseWord",
  props: {
    wordLen: Number,
  },
  emits: ["set-word"],
  data() {
    return {
      word: "",
    };
  },
  computed: {
    wordInvalid() {
      return !(
        this.word &&
        this.word.split(/\W+/).length === 1 &&
        this.word.length === this.wordLen
      );
    },
  },
  created() {
    // Only for test purpose
    // this.$emit("set-word", "PIRANA");
  },
};
</script>

<style>
</style>