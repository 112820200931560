<template>
  <div class="row justify-content-center">
    <div class="col-auto m-1 p-0" v-for="(letter, idx) in word" :key="idx">
      <div class="vstack mx-auto">
        <div class="border-bottom border-3 border-dark">
          <button
            class="btn"
            :class="{
              'btn-primary': selectedIdx === idx,
              'btn-danger': guessedIndexes.includes(idx),
            }"
            @click="$emit('toggle-selected-idx', idx)"
            :disabled="
              (proposedLetter != '' && proposedLetter != letter) ||
              guessedIndexes.includes(idx)
            "
          >
            {{ letter ? letter : "?" }}
          </button>
        </div>
        <div class="text-center">
          <button class="btn btn-light shadow-none p-0" disabled>
            {{ idx + 1 }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Word",
  components: {},
  props: {
    word: String,
    selectedIdx: Number,
    proposedLetter: {
      type: String,
      default: "",
    },
    guessedIndexes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["toggle-selected-idx"],
  data() {
    return {};
  },
  created() {
    // console.log(this.word);
  },
  methods: {},
};
</script>

<style>
.col {
  width: fit-content;
}
</style>