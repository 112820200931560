<template>
  <div class="container-fluid about">
    <div class="row m-0 p-0">
      <div
        class="content col col-auto h-auto p-3 my-3 mx-auto text-center rounded"
      >
        <h1 class="fw-bold">Bienvenue sur Guess What</h1>
        <h3>
          <span class="sp1">Une idée originale de </span>
          <span class="sp2">Saoussane</span>
        </h3>
        <h3>
          <span class="sp3">Réalisée par </span>
          <span class="sp4">Abdoulaye</span>
        </h3>

        <router-link
          :to="{ name: 'Home' }"
          class="btn btn-primary btn-lg"
          type="button"
          >Continuer</router-link
        >
      </div>
    </div>
  </div>
</template>

<style>
.about {
  background-image: url("../assets/cake.jpg");
  height: 100%;
}
.content {
  background-color: rgba(240, 248, 255, 0.8);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sp1,
.sp2,
.sp3,
.sp4 {
  animation-name: fadeIn 5s;
}

.sp1 {
  animation-delay: 1s;
}

.sp2 {
  color: rgb(139, 0, 139);
  animation-delay: 6s;
}

.sp3 {
  animation-delay: 11s;
}

.sp4 {
  color: red;
  animation-delay: 15s;
}
</style>