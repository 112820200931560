<template>
  <div class="card" style="width: 18rem">
    <img :src="image" class="card-img-top" alt="..." />
    <div class="card-body text-center">
      <h5 class="card-title">Resultat de la partie</h5>
      <p class="card-text">{{ msg }}</p>
      <a href="#" class="btn btn-primary" @click="newGame()">Nouveau Jeu</a>
    </div>
  </div>
</template>

<script>
import winImg from "@/assets/youwin.jpg";
import loseImg from "@/assets/youlose.jpg";

export default {
  name: "WinLose",
  props: ["status", "msg"],
  data() {
    return {
      image: this.status == "win" ? winImg : loseImg,
    };
  },
  methods: {
    newGame() {
      window.sessionStorage.clear();
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style>
</style>