<template>
  <div class="row text-center">
    <h3 class="mt-3">Lettres disponibles</h3>
    <p class="text-info bg-light border border-info">
      <strong>Instructions:</strong> Selectionner une lettre et cliquer sur
      Proposer.
    </p>
    <div>
      <button
        v-for="(letter, idx) in letters"
        :key="idx"
        class="btn mb-2"
        :class="[
          exhausted.includes(letter) ? 'btn-secondary' : '',
          idx + 1 != letters.length ? 'me-2' : '',
          selectedLetter == letter ? 'btn-primary' : '',
        ]"
        :disabled="exhausted.includes(letter)"
        @click="toggleSelectedLetter(letter)"
      >
        {{ letter }}
      </button>
    </div>
    <div class="">
      <button
        v-if="myturn"
        class="btn btn-success py-2"
        type="button"
        :disabled="selectedLetter == ''"
        @click="proposeLetter()"
      >
        Proposer cette lettre
      </button>
      <button
        v-if="myturn"
        class="btn btn-primary py-2 ms-2"
        type="button"
        @click="showModal = true"
      >
        Proposer un mot
      </button>
    </div>
    <!-- <propose-word-modal
      :show="showModal"
      @close="showModal = false"
      @propose-word="showModal = false"
    ></propose-word-modal> -->
    <modal :show="showModal" @close="showModal = false" @submit="proposeWord()">
      <template v-slot:title> Proposer un mot </template>
      <template v-slot:body>
        <input
          type="text"
          v-model="word"
          :required="wordInvalid"
          :class="['form-control py-2', wordInvalid ? 'is-invalid' : '']"
          placeholder="Mot"
          aria-label="Mot"
        />
        <div class="invalid-feedback text-start" v-if="wordInvalid">
          Veuillez renseigner un mot sans espace
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// import ProposeWordModal from "@/components/ProposeWordModal.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "Alphabet",
  components: { Modal },
  props: {
    exhausted: Array,
    myturn: Boolean,
  },
  data() {
    return {
      letters: Array.from(Array(26))
        .map((e, i) => i + 65)
        .map((x) => String.fromCharCode(x)),
      selectedLetter: "",
      showModal: false,
      word: "",
      wordInvalid: false,
    };
  },
  methods: {
    toggleSelectedLetter(letter) {
      if (this.selectedLetter == letter) this.selectedLetter = "";
      else this.selectedLetter = letter;
    },
    proposeLetter() {
      this.$emit("propose-letter", this.selectedLetter);
      this.selectedLetter = "";
    },
    proposeWord() {
      if (this.word.length > 0 && this.word.split(/\W+/).length === 1) {
        this.$emit("propose-word", this.word.toUpperCase());
        this.showModal = false;
      } else this.wordInvalid = true;
    },
  },
};
</script>