<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary p-0">
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <router-link :to="{ name: 'Home' }" class="nav-link active"
            >Home</router-link
          >
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'About' }" class="nav-link active"
            >About</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
  <router-view />
</template>

<style lang="scss">
@import "~bootswatch/dist/materia/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/materia/bootswatch";
</style>
