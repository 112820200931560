<template>
  <div class="container text-center p-3">
    <choose-word v-if="!mywordSet" :wordLen="wordLen" @set-word="setmyword" />
    <div class="row justify-content-center" v-else>
      <win-lose
        v-if="result"
        :status="result"
        :msg="result == 'win' ? wordToGuessStatus : myWordStatus"
      ></win-lose>
      <div class="accordion mt-5">
        <div class="accordion-item">
          <div class="accordion-header">
            <button
              :class="[
                'accordion-button py-2 text-center',
                wordToGuessCollapse ? 'collapsed' : '',
              ]"
              type="button"
              @click="wordToGuessCollapse = !wordToGuessCollapse"
            >
              <h2 class="m-0">Mot à deviner</h2>
            </button>
          </div>
          <div
            :class="[
              'accordion-collapse collapse',
              wordToGuessCollapse ? '' : 'show',
            ]"
            aria-labelledby="wordToGuessHeading"
          >
            <div class="accordion-body justify-content-center">
              <Word
                :word="wordToGuess"
                :selectedIdx="-1"
                :guessedIndexes="guessedIndexesInWordToGuess"
              />
              <Alphabet
                :exhausted="exhausted_letters"
                :myturn="myturn"
                @propose-letter="proposeLetter"
                @propose-word="proposeWord"
              />
            </div>
            <div class="shadow border border-primary text-center">
              <h2>Status</h2>
              <p class="text-success" v-if="myturn">C'est votre tour</p>
              <p class="text-danger" v-else>Ce n'est pas votre tour</p>
              <p class="text-info" v-if="myturn">
                {{ myWordStatus }} <br v-if="myWordStatus" />
                {{ wordToGuessStatus }}
              </p>
              <p class="text-info" v-else>
                {{ wordToGuessStatus }} <br v-if="wordToGuessStatus" />
                {{ myWordStatus }}
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-header" id="mywordHeading">
            <button
              :class="[
                'accordion-button py-2',
                mywordCollapse ? 'collapsed' : '',
              ]"
              type="button"
              @click="mywordCollapse = !mywordCollapse"
            >
              <h2 class="m-0">Mon Mot</h2>
            </button>
          </div>
          <div
            :class="[
              'accordion-collapse collapse',
              mywordCollapse ? '' : 'show',
            ]"
            aria-labelledby="mywordHeading"
          >
            <div class="row accordion-body justify-content-center">
              <Word
                :word="myword"
                :selectedIdx="selectedIdx"
                :guessedIndexes="guessedIndexesInMyWord"
                :proposedLetter="proposedLetter"
                @toggle-selected-idx="toggleSelectedIdx"
              />
              <div class="col-auto mx-auto">
                <div class="input-group" v-if="proposedLetter != '' && !myturn">
                  <button class="btn btn-light" disabled>
                    Lettre proposée: {{ proposedLetter }}
                  </button>
                  <button
                    class="btn btn-success py-2"
                    type="button"
                    :disabled="selectedIdx == -1"
                    @click="acceptLetter()"
                  >
                    Accepter cette lettre
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Word from "@/components/Word.vue";
import Alphabet from "@/components/Alphabet.vue";
import ChooseWord from "@/components/ChooseWord.vue";
import WinLose from "@/components/WinLose.vue";

function replaceChar(origString, replaceChar, index) {
  let firstPart = origString.substr(0, index);
  let lastPart = origString.substr(index + 1);

  let newString = firstPart + replaceChar + lastPart;
  return newString;
}

function removeLetters(origString, indexes) {
  let newString = "";
  for (let idx = 0; idx < origString.length; idx++) {
    if (!indexes.includes(idx)) newString += origString[idx];
  }
  return newString;
}

export default {
  name: "Home",
  props: ["id"],
  components: {
    Word,
    Alphabet,
    ChooseWord,
    WinLose,
  },
  data() {
    ChooseWord;
    return {
      wordLen: 0,
      myword: "", // My word
      mywordSet: false,
      mywordInvalid: false,
      wordToGuess: "", // Word of the competitor
      creator: false,
      exhausted_letters: [],
      selectedIdx: -1,
      chatSocket: null,
      myturn: false,
      mywordCollapse: false,
      wordToGuessCollapse: false,
      user: window.localStorage.getItem("pseudo"),
      proposedLetter: "",
      proposedWord: "",
      guessedIndexesInMyWord: Array(),
      guessedIndexesInWordToGuess: Array(),
      myWordStatus: "",
      wordToGuessStatus: "",
      result: "",
    };
  },
  methods: {
    toggleSelectedIdx(idx) {
      this.selectedIdx = this.selectedIdx === idx ? -1 : idx;
      // console.log("selected idx", idx);
    },
    setmyword(word) {
      this.myword = word;
      this.mywordSet = true;
    },
    sendMessage(msg) {
      if (this.chatSocket == null) {
        // Initiate web socket for communication
        // new WebSocket("ws://api.hostfaso.net/ws/guesswhat/game/test/");
        this.chatSocket = new WebSocket(
          "wss://api.hostfaso.net/ws/guesswhat/game/" + this.id + "/"
        );
      }
      if (this.chatSocket.readyState != this.chatSocket.OPEN) {
        this.chatSocket.onopen = () => {
          this.chatSocket.send(msg);
        };
      } else {
        this.chatSocket.send(msg);
      }
    },
    proposeLetter(letter) {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "propose-letter",
          value: letter,
        })
      );
      this.proposedLetter = letter;
      this.wordToGuessStatus = `Vous avez proposé la lettre '${this.proposedLetter}'`;
      // console.log("proposed letter", letter);
      this.myturn = true;
      this.myWordStatus = "";
    },
    proposeWord(word) {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "propose-word",
          value: word,
        })
      );
      this.proposedWord = word;
      this.wordToGuessStatus = `Vous avez proposé le mot '${this.proposedWord}'`;
      this.myturn = true;
      this.myWordStatus = "";
    },
    acceptLetter() {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "accept-letter",
          value: this.selectedIdx,
        })
      );
      this.myWordStatus = `Vous avez accepté la lettre '${this.proposedLetter}'`;
      this.proposedLetter = "";
      this.myturn = true;
      this.guessedIndexesInMyWord.push(this.selectedIdx);
      this.wordToGuessStatus = "A vous de proposer une lettre ou un mot";
    },
    acceptWord(word) {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "accept-word",
          value: word,
        })
      );
      this.myWordStatus = "Vous avez perdu!";
      this.result = "lose";
      this.wordToGuessStatus = "";
      this.guessedIndexesInWordToGuess = Array.from(
        Array(this.wordLen),
        (x, i) => i
      );
      this.proposedWord = "";
      this.myturn = false;
      this.wordToGuessCollapse = true;
      this.mywordCollapse = true;
    },
    rejectLetter(letter) {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "reject-letter",
          value: letter,
        })
      );
      this.myWordStatus = `Vous avez rejeté la lettre '${letter}'`;
      this.proposedLetter = "";
      this.myturn = true;
      this.wordToGuessStatus = "A vous de proposer une lettre ou un mot";
    },
    rejectWord(word) {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "reject-word",
          value: word,
        })
      );
      this.myWordStatus = `Vous avez rejeté le mot '${word}'`;
      this.proposedWord = "";
      this.myturn = true;
      this.wordToGuessStatus = "A vous de proposer une lettre ou un mot";
    },
    sendWorldLen() {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "set-word-len",
          value: this.wordLen,
        })
      );
      // console.log("set-word-len sent!", this.wordLen);
    },
    requestWorldLen() {
      this.sendMessage(
        JSON.stringify({
          user: this.user,
          action: "request-word-len",
          value: this.wordLen,
        })
      );
      // console.log("request-word-len sent!", this.wordLen);
    },
    processMessage(data) {
      if (this.user != data.user) {
        // console.log("message received", data);
        if (data.action == "set-word-len") {
          this.wordLen = data.value;
          this.wordToGuess = "?".repeat(this.wordLen);
        } else if (data.action == "request-word-len") {
          this.sendWorldLen();
        } else if (data.action == "propose-letter") {
          if (
            removeLetters(this.myword, this.guessedIndexesInMyWord).includes(
              data.value
            )
          ) {
            if (this.guessedIndexesInMyWord == this.wordLen - 1) {
              this.acceptWord(this.myword);
            } else {
              this.proposedLetter = data.value;
              this.myWordStatus = `${data.user} vous propose la lettre '${this.proposedLetter}'`;
              this.wordToGuessStatus = "";
            }
          } else {
            this.rejectLetter(data.value);
          }
        } else if (data.action == "propose-word") {
          if (this.myword == data.value) {
            this.acceptWord(data.value);
          } else {
            this.rejectWord(data.value);
          }
        } else if (data.action == "accept-letter") {
          this.guessedIndexesInWordToGuess.push(data.value);
          this.myturn = false;
          this.wordToGuess = replaceChar(
            this.wordToGuess,
            this.proposedLetter,
            data.value
          );
          this.wordToGuessStatus = `La lettre que vous avez proposé '${this.proposedLetter}' est bien valide :)`;
          this.myWordStatus = "Attendez la proposition de votre adversaire";
          this.proposedLetter = "";
          this.selectedIdx = -1;
        } else if (data.action == "reject-letter") {
          this.exhausted_letters.push(data.value);
          this.wordToGuessStatus = `La lettre que vous avez proposé '${this.proposedLetter}' n'est pas valide :(`;
          this.myWordStatus = "Attendez la proposition de votre adversaire";
          this.myturn = false;
          this.selectedIdx = -1;
          this.proposedLetter = "";
        } else if (data.action == "accept-word") {
          this.wordToGuess = data.value;
          this.wordToGuessStatus = `Bravo vous avez gagné! Mot: '${data.value}' :)))`;
          this.result = "win";
          this.myWordStatus = "";
          this.myturn = false;
          this.proposedWord = "";
          this.wordToGuessCollapse = true;
          this.mywordCollapse = true;
        } else if (data.action == "reject-word") {
          this.wordToGuessStatus = `Le mot que vous avez proposé '${this.proposedWord}' n'est pas valide :(`;
          this.myWordStatus = "Attendez la proposition de votre adversaire";
          this.myturn = false;
          this.proposedWord = "";
        }
      }
    },
  },
  created() {
    // const gameId = window.sessionStorage.getItem("gameId");
    const wordLen = Number(window.sessionStorage.getItem("wordLen"));
    const creator = window.sessionStorage.getItem("creator") == "true";
    // console.log(
    //   window.sessionStorage.getItem("gameId"),
    //   window.sessionStorage.getItem("wordLen"),
    //   window.sessionStorage.getItem("creator")
    // );
    // console.log(gameId, wordLen, creator);
    if (creator) {
      this.wordLen = wordLen;
      this.wordToGuess = "?".repeat(wordLen);
      this.creator = creator;
      this.myturn = true;
      this.wordToGuessStatus = "A vous de proposer une lettre ou un mot";
      this.sendWorldLen();
    } else {
      this.myturn = false;
      this.myWordStatus = "Attendez la proposition de votre adversaire";
      this.requestWorldLen();
    }

    this.chatSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      this.processMessage(data);
    };

    this.chatSocket.onclose = () => {
      // console.error("Chat socket closed unexpectedly", e);
      alert("La connexion avec le serveur a été perdue.");
    };
  },
  watch: {
    // whenever question changes, this function will run
    // myturn(newval, oldval) {
    //   // console.log("My turn changed from", oldval, "to", newval);
    // },
  },
};
</script>
