<!-- template for the modal component -->
<template>
  <div
    :class="['modal show', show ? 'fade' : '']"
    :style="{
      display: show ? 'block' : 'none',
      backgroundColor: 'rgba(47,79,79, 0.5)',
    }"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><slot name="title"> default title </slot></h5>
          <button
            type="button"
            class="btn-close"
            @click="$emit('close')"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body"> default body </slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="$emit('close')"
          >
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="$emit('submit')"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["show"],
  emits: ["close", "submit"],
  watch: {
    // show(oldVal, newVal) {
    //   if (newVal) document.body.style.backgroundColor = "gray";
    //   else document.body.style.backgroundColor = "white";
    // },
  },
};
</script>