<template>
  <div class="container m-5 text-center">
    <h2>Choisis un pseudo</h2>
    <form class="col-md-6 mx-auto">
      <div class="input-group">
        <input
          type="text"
          v-model="pseudo"
          class="form-control py-2"
          placeholder="Pseudonyme"
          aria-label="Pseudonyme"
        />
        <button class="btn btn-primary py-2" type="button" @click="setPseudo()">
          Valider
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      pseudo: "",
    };
  },
  methods: {
    setPseudo() {
      if (!this.pseudo) alert("Veuillez renseigner un pseudonyme");
      else {
        window.localStorage.setItem("pseudo", this.pseudo);
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style>
</style>