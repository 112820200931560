<template>
  <div class="container mt-5 text-center">
    <h1 class="mb-5">Bonjour {{ pseudo }}</h1>
    <form class="row" v-if="!gameCreated">
      <div class="col-md-6 mx-auto">
        <div class="input-group has-validation">
          <input
            type="text"
            :class="['form-control py-2', nbLettersInvalid ? 'is-invalid' : '']"
            placeholder="Nb Lettres"
            v-model="wordLen"
            :required="nbLettersInvalid"
          />
          <button
            class="btn btn-primary py-2"
            type="button"
            @click="startGame()"
          >
            Démarrer un nouveau jeu
          </button>
          <div class="invalid-feedback text-start" v-if="nbLettersInvalid">
            Veuillez renseigner un nombre entre 5 et 30
          </div>
        </div>
        <h3 class="my-5">OU</h3>
        <div class="input-group">
          <input
            type="text"
            class="form-control py-2"
            placeholder="Entrez un numéro de jeu"
            v-model="gameId"
          />
          <button
            class="btn btn-success py-2"
            type="button"
            @click="joinGame()"
          >
            Participer à un Jeu existant
          </button>
        </div>
      </div>
    </form>
    <div class="text-center" v-else>
      <div>
        <h1>
          Code du Jeu: <span class="text-primary">{{ gameId }}</span>
        </h1>
        <!-- <h1>
          <a class="btn btn-primary" href="#" role="button">Copier</a>
        </h1> -->
        <p class="text-info bg-light border border-info">
          <strong>Instructions:</strong> Copier ce numéro et envoyez le à votre
          adversaire pour qu'il puisse se connecter au jeu. Puis cliquez sur
          Démarrer.
        </p>
      </div>
      <div class="mt-5">
        <router-link
          :to="{ name: 'Game', params: { id: gameId } }"
          class="btn btn-success"
          type="button"
        >
          Démarrer le jeu
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
function getRandomString(length) {
  var randomChars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

export default {
  name: "Home",
  components: {},
  data() {
    return {
      gameId: "",
      gameCreated: false,
      pseudo: "",
      wordLen: null,
      nbLettersInvalid: false,
    };
  },
  created() {
    if (!window.localStorage.getItem("pseudo")) {
      this.$router.push({ name: "Login" });
    } else {
      this.pseudo = window.localStorage.getItem("pseudo");
    }
    //Initialize game variables
    window.sessionStorage.clear();
    // console.log(window.sessionStorage.getItem("creator"));
  },
  computed: {},
  methods: {
    startGame() {
      if (!Number.isInteger(Number(this.wordLen))) {
        this.nbLettersInvalid = true;
        // console.log("Invalid number", this.wordLen);
      } else {
        // console.log(this.wordLen, parseInt(this.wordLen));
        this.nbLettersInvalid = false;
        this.gameId = getRandomString(5);
        window.sessionStorage.setItem("gameId", this.gameId);
        window.sessionStorage.setItem("wordLen", Number(this.wordLen));
        window.sessionStorage.setItem("creator", true);
        this.gameCreated = true;
      }
    },
    joinGame() {
      window.sessionStorage.setItem("gameId", this.gameId);
      window.sessionStorage.setItem("creator", false);
      this.$router.push({ name: "Game", params: { id: this.gameId } });
    },
  },
};
</script>

<style>
body {
  background-image: none;
}
</style>
